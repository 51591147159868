.navigation-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;

  .action-button {
    border-radius: 2.5rem;
    font-size: 1rem;
    font-weight: bold;
  }

  .nav-container {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;

    .nav-item-container {
      margin: 1rem 0 3rem;
      display: flex;
      justify-content: center;
    }
  }

  .hidden-trigger {
    position: absolute;
    right: 6%;
    top: 7rem;
    height: 2rem;
    width: 2rem;
    border-radius: 0.25rem;
    cursor: pointer;

    &:hover {
      box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.3);
    }
  }
}
