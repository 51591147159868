.pdf-viewer {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  .react-pdf__Document {
    box-shadow: 2px 2px 10px 0 rgb(34 36 38 / 85%);
  }

  .react-pdf__Page {
    display: flex;
    justify-content: center;
  }

  .react-pdf__Document,
  .react-pdf__Page__canvas,
  .react-pdf__Page__svg {
    border-radius: 0.5rem;
  }

  .pdf-toolbar {
    width: 100%;
    margin-bottom: 1rem;
    padding: 0 0.5rem;
    border-radius: 0.25rem;
    background-color: #eee;
    border: 1px solid rgba(34, 36, 38, 0.15);
    display: flex;

    .toolbar-button {
      padding: 0.5rem;
    }

    .button-label {
      color: black;
      cursor: auto;
      background-color: white;
    }

    .toolbar-item-group {
      flex: 6;
    }

    .toolbar-item {
      flex: 1;
    }
  }
}
