.upload-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem;
  border: 0.2rem dashed #eeeeee;
  border-radius: 0.25rem;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  cursor: pointer;

  .upload-title-container {
    text-align: center;
    color: black;
  }

  .upload-title {
    font-size: 1.25rem;
    margin-top: 0.5rem;
  }

  .upload-description {
    font-weight: bold;
    color: rgba(0, 0, 0, 0.7);
  }

  &.focused-upload {
    border-color: #2196f3;
  }

  &.accepted-upload {
    border-color: #00e676;
  }

  &.rejected-upload {
    border-color: #ff1744;
  }
}
