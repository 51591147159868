.social-media-section {
  text-align: center;
  margin-top: -106px;

  #github {
    background-color: #000000;
  }

  #instagram {
    background-color: #dd2a7b;
  }

  #facebook {
    background-color: #3b5998;
  }

  #linkedin {
    background-color: #0077b5;
  }

  #email {
    background-color: #1f76eb;
  }
}
