.medium-logo {
  max-height: 2rem;
}

.small-logo {
  max-height: 1.75rem;
}

.mini-logo {
  max-height: 1.5rem;
}

.tiny-logo {
  max-height: 1.25rem;
}

.vertical-space-margin > * {
  margin-bottom: 0.75rem !important;
}

.vertical-space-margin.wide > * {
  margin-bottom: 3rem !important;
}

.vertical-space-margin > *:first-child {
  margin-top: 0 !important;
}

.vertical-space-margin > *:last-child {
  margin-bottom: 0 !important;
}

.horizontal-space-margin > * {
  margin-right: 0.75rem !important;
}

.horizontal-space-margin > *:first-child {
  margin-left: 0 !important;
}

.horizontal-space-margin > *:last-child {
  margin-right: 0 !important;
}

.list-group-item-heading {
  font-weight: bold;
}

.inverted-tooltip {
  color: white;

  .tooltip-inner {
    background-color: black;
  }

  .arrow::before {
    border-top-color: black !important;
    border-bottom-color: black !important;
  }
}

.vertical-timeline.vertical-timeline-custom-line::before {
  background: #424242;
}

.vertical-timeline-element {
  .content-container {
    color: black;
    box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.4);
    border-top: 3px solid;
    font-size: 1rem;
  }

  .vertical-timeline-element-content .vertical-timeline-element-date {
    color: #424242;
    font-weight: bold;
  }

  .vertical-timeline-element-icon {
    color: #fff;
    border: 4px solid #424242;
    box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.4);

    .icon-element {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.25rem;
    }
  }
}

.tools {
  font-size: 4rem;

  .icon-text {
    display: inline-grid;
    padding: 0.2rem 0.2rem 0.5rem;
    text-align: center;

    .text {
      color: #444c5c;
      font-size: 0.8rem;
      padding-top: 0.5rem;
      opacity: 0;
      font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
    }

    &:hover .text {
      opacity: 1;
      transition: 0.5s;
    }
  }
}

@-webkit-keyframes bob {
  0% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
  50% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
  }
  100% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
}
@keyframes bob {
  0% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
  50% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
  }
  100% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
}
@-webkit-keyframes bob-float {
  100% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
}
@keyframes bob-float {
  100% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
}
.hover-bob {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hover-bob:hover,
.hover-bob:focus,
.hover-bob:active {
  -webkit-animation-name: bob-float, bob;
  animation-name: bob-float, bob;
  -webkit-animation-duration: 0.3s, 1.5s;
  animation-duration: 0.3s, 1.5s;
  -webkit-animation-delay: 0s, 0.3s;
  animation-delay: 0s, 0.3s;
  -webkit-animation-timing-function: ease-out, ease-in-out;
  animation-timing-function: ease-out, ease-in-out;
  -webkit-animation-iteration-count: 1, infinite;
  animation-iteration-count: 1, infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-direction: normal, alternate;
  animation-direction: normal, alternate;
}

.infinite-bob {
  -webkit-animation: bob 1.5s ease-in-out infinite;
  animation: bob 1.5s ease-in-out infinite;
}
