.video-player-wrapper {
  position: relative;
  aspect-ratio: 16/9;
  width: 100%;
}

.loader-container {
  position: absolute;
  background-color: white;
  z-index: 100;
  height: 100%;
  width: 100%;
}
