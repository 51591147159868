.grades-section {
  .content-container {
    max-height: 30rem;
    overflow-y: auto;
    padding-top: 0;
    margin-top: 1.75rem;

    .gpa-badge-container {
      font-size: 1.5rem;
    }

    .grades-overview {
      font-size: 1.5rem;
      font-weight: 500;
    }
  }
}
