.page-header:before {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  display: block;
  left: 0;
  top: 0;
  content: "";
  background-color: rgba(0, 0, 0, 0.5);
}

.parallax-container {
  will-change: transform; // for browser optimization
}

.photo-container {
  width: 12rem;
  height: 12rem;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
  box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.hidden-content {
  background-color: #152a3d;
  font-family: Pacifico, cursive;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
