// This file has been autogenerated by grunt task lessToSass. Any changes will be overwritten.

.nav-tabs {
  border: 0;
  padding: $padding-large-vertical $padding-base-horizontal;
  cursor: pointer;

  &:not(.nav-tabs-neutral) > .nav-item > .nav-link.active {
    box-shadow: 0px 5px 35px 0px rgba(0, 0, 0, 0.3);
  }

  .card & {
    border-top-right-radius: $border-radius-small;
    border-top-left-radius: $border-radius-small;
  }

  > .nav-item {
    > .nav-link {
      color: $default-color;
      margin: 0;
      margin-right: 5px;
      background-color: $transparent-bg;
      border: 1px solid $transparent-bg;
      border-radius: 30px;
      font-size: $font-size-base;
      padding: $padding-btn-vertical $padding-round-horizontal;
      line-height: $line-height-general;

      &:hover {
        background-color: $transparent-bg;
      }

      &.active {
        background-color: $light-black;
        border-radius: 30px;
        color: $white-color;
      }

      i.now-ui-icons {
        font-size: 14px;
        position: relative;
        top: 1px;
        margin-right: 3px;
      }
    }

    &.disabled > .nav-link,
    &.disabled > .nav-link:hover {
      color: rgba(255, 255, 255, 0.5);
    }
  }

  &.nav-tabs-neutral {
    > .nav-item {
      > .nav-link {
        color: $white-color;

        &.active {
          background-color: $opacity-2;
          color: $white-color;
        }
      }
    }
  }

  &.nav-tabs-primary {
    > .nav-item {
      > .nav-link {
        &.active {
          background-color: $primary-color;
        }
      }
    }
  }

  &.nav-tabs-info {
    > .nav-item {
      > .nav-link {
        &.active {
          background-color: $info-color;
        }
      }
    }
  }

  &.nav-tabs-danger {
    > .nav-item {
      > .nav-link {
        &.active {
          background-color: $danger-color;
        }
      }
    }
  }

  &.nav-tabs-warning {
    > .nav-item {
      > .nav-link {
        &.active {
          background-color: $warning-color;
        }
      }
    }
  }

  &.nav-tabs-success {
    > .nav-item {
      > .nav-link {
        &.active {
          background-color: $success-color;
        }
      }
    }
  }
}
