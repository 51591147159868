.pdf-uploader {
  height: 20rem;
}

.url-input-form {
  width: 100%;
  max-width: 30rem;

  .url-input-group {
    cursor: auto;

    .input-group-text {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
    }

    input {
      border-left: none;
      background-color: white;

      &:focus {
        border-top-color: #e3e3e3;
        border-bottom-color: #e3e3e3;
        border-right-color: #e3e3e3;
      }
    }

    button {
      margin: 0;
    }
  }
}
