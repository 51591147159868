.placeholder-wrapper {
  text-align: center;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &.placeholder {
    min-height: 18rem;
  }

  .message {
    font-size: 1.25rem;
  }
}
